export { matchers } from './client-matchers.js';

export const components = [
	() => import("..\\..\\src\\routes\\__layout.svelte"),
	() => import("..\\runtime\\components\\error.svelte"),
	() => import("..\\..\\src\\routes\\about.svelte"),
	() => import("..\\..\\src\\routes\\entries\\[id].svelte"),
	() => import("..\\..\\src\\routes\\entries\\all\\[id].svelte"),
	() => import("..\\..\\src\\routes\\halloffame.svelte"),
	() => import("..\\..\\src\\routes\\index.svelte"),
	() => import("..\\..\\src\\routes\\indexWithSplide.svelte"),
	() => import("..\\..\\src\\routes\\invites.svelte"),
	() => import("..\\..\\src\\routes\\login.svelte"),
	() => import("..\\..\\src\\routes\\payment.svelte"),
	() => import("..\\..\\src\\routes\\profile.svelte"),
	() => import("..\\..\\src\\routes\\registration.svelte"),
	() => import("..\\..\\src\\routes\\seasons\\[id].svelte"),
	() => import("..\\..\\src\\routes\\weeks\\[id]\\picks.svelte"),
	() => import("..\\..\\src\\routes\\weeks\\[id].svelte")
];

export const dictionary = {
	"": [[0, 6], [1]],
	"about": [[0, 2], [1]],
	"halloffame": [[0, 5], [1]],
	"indexWithSplide": [[0, 7], [1]],
	"invites": [[0, 8], [1]],
	"login": [[0, 9], [1]],
	"payment": [[0, 10], [1]],
	"profile": [[0, 11], [1]],
	"registration": [[0, 12], [1]],
	"entries/all/[id]": [[0, 4], [1]],
	"entries/[id]": [[0, 3], [1]],
	"seasons/[id]": [[0, 13], [1]],
	"weeks/[id]": [[0, 15], [1]],
	"weeks/[id]/picks": [[0, 14], [1]]
};